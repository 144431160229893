<template>
  <div class="app-footer">
    <base-content-wrapper-new>

      <base-grid gap="6vmin">
        <div>
          <editable-content path="footer.aboutTitle">
            <base-title size="m" mB="s"></base-title>
          </editable-content>
          <editable-content path="footer.aboutText">
            <base-text></base-text>
          </editable-content>
          
          <editable-content v-if="$api.isAdmin()" path="footer.test">
            <base-image></base-image>
          </editable-content>

          <base-flex center="y" mT="m" mB="s">
            <!-- <base-text class="email" @click.native="copyToClipboard('footer--constlet-email')" size="l" mT="m" mB="s">{{ this.GET_OFFICIAL.email }}</base-text> -->
            <base-text class="email" @click.native="copyToClipboard('footer--constlet-email')" size="l">{{ this.GET_OFFICIAL.email }}</base-text>
            <input id="footer--constlet-email" type="hidden" :value="GET_OFFICIAL.email">
            <base-text v-if="showCopied" mL="l" :mT="1.5">Copied!</base-text>
          </base-flex>
          <base-text>{{ this.GET_OFFICIAL.phone }}</base-text>

          <base-flex mT="l">
            <base-icon
              v-for="app in externalApps"
              :key="app.title"
              :tooltip="app.tooltip"
              @click="comingSoon"
              size="m"
              mR="l"
            >{{ app.title }}</base-icon>
            <base-text v-if="showComingSoon">Coming soon!</base-text>
          </base-flex>
        </div>
        
        <base-flex :column=true center="x" :pT=10>
          <base-text
            v-for="link in navLinks"
            :key="link.title"
            @click.native="moveTo(link)"
            class="link"
            mB="m"
          >{{ inEnglish ? link.title : link.titleFi }}</base-text>
        </base-flex>
      </base-grid>

      <base-flex :column=true center="x" mT="s">
        <base-flex class="back-to-top" @click.native="returnToTop" center="xy" :column="true" mT="l">
          <base-icon :mB="-5">up</base-icon>
          <base-icon>up</base-icon>
        </base-flex>
        <base-text size="s" mT="m">
          {{ this.GET_OFFICIAL.watermark }}. {{ this.GET_OFFICIAL.rights }}
        </base-text>
        <base-button @click="openModal" mT="m">{{ this.$api.isLogged() ? 'Logout' : 'Login' }}</base-button>
      </base-flex>


    </base-content-wrapper-new>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { copyToClipboard } from '@/utils/execute'

export default {
  name: 'appFooter',
  
  components: { editableContent: () => import('@/components/editableContent') },
  
  props: {
    textContent: Object,
  },

  data() {
    return {
      externalApps: [
        { title: 'instagram', tooltip: 'Coming soon!' },
        { title: 'facebook', tooltip: 'Coming soon!' },
        { title: 'linkedin', tooltip: 'Coming soon!' }
      ],
      showComingSoon: false,
      showCopied: false
    }
  },

  computed: {
    ...mapGetters('app', ['GET_OFFICIAL']),

    inEnglish() {
      return this.$store.state.app.constlet.inEnglish
    },

    navLinks() {
      return this.$store.state.app.constlet.sections
    }
  },

  methods: {
    copyToClipboard(id) {
      copyToClipboard(id)
      if (!this.showCopied) {
        this.showCopied = true
        
        setTimeout(() => {
          this.showCopied = false
        }, 3000)
      }
    },

    comingSoon() {
      if (!this.showComingSoon) {
        this.showComingSoon = true
        
        setTimeout(() => {
          this.showComingSoon = false
        }, 3000)
      }
    },

    openModal() {
      if (this.$api.isLogged()) {
        this.$store.dispatch('modals/SET_MODAL', { active: 'logout' })
      } else {
        this.$store.dispatch('modals/SET_MODAL', { active: 'login' })
      }
    },

    moveTo(link) {
      const section = document.getElementById(link.id)
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    },

    returnToTop () { // TODO!: ULKOISTA TÄMÄ UTILSSIIN!
      let scrollDuration = 500
      let cosParameter = window.scrollY / 2
      let scrollCount = 0
      let oldTimestamp = performance.now()

      function step (newTimestamp) {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
        if (scrollCount >= Math.PI) window.scrollTo(0, 0)
        if (window.scrollY === 0) return
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
        oldTimestamp = newTimestamp
        window.requestAnimationFrame(step)
      }

      window.requestAnimationFrame(step)

      /*
      See: https://stackoverflow.com/questions/21474678/scrolltop-animation-without-jquery

      Explanations:
      - pi is the length/end point of the cosinus intervall (see above)
      - newTimestamp indicates the current time when callbacks queued by requestAnimationFrame begin to fire.
        (for more information see https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame)
      - newTimestamp - oldTimestamp equals the duration

        a * cos (bx + c) + d                      | c translates along the x axis = 0
      = a * cos (bx) + d                          | d translates along the y axis = 1 -> only positive y values
      = a * cos (bx) + 1                          | a stretches along the y axis = cosParameter = window.scrollY / 2
      = cosParameter + cosParameter * (cos bx)    | b stretches along the x axis = scrollCount = Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
      = cosParameter + cosParameter * (cos scrollCount * x)
      */
    }
  }
}
</script>

<style lang="scss" scoped>
$footer--color: $app-color--theme;
$footer--color-bg: $app-color--main;

.app-footer {
  padding-top: 3rem;
  padding-bottom: 1.8rem;
  display: flex;
  flex-direction: column;
  background-color: $footer--color-bg;

  .base-title,
  .base-text,
  .base-icon { color: $footer--color; }

  .email,
  .link {
    font-weight: 700;
    @extend %clickable;
  }

  .watermark {
    margin-top: 1rem;
  }

  .return {
    @extend %clickable;
    span {
      display: block;
      text-align: center;
      font-size: 0.85rem;
    }
  }

  .back-to-top {
    width: 50px;
    height: 50px;
    border: 1px solid $footer--color;
    border-radius: 3px;
    @extend %clickable;
    &:hover {
      border: 1px solid $footer--color-bg;
      background: $app-color--hl;
      .base-icon { color: $footer--color-bg; }
    }
  }
}
</style>
