<template>
  <div class="home-references">
    <base-title :size="styling" mB="xl">{{ textContent.section }}</base-title>

    <base-grid fillType="fill" :gap="3">
      <div
        v-for="ref in refs"
        :key="ref.id"
        class="item"
        @click="openReference(ref)"
      >
        <base-bg :source="`${$store.state.api.imageURL}${ref.id}/${ref.id}_pic0.png`" :full="true"></base-bg>
        <!-- <span class="handle">category</span> -->
        <!-- <base-title size="s">{{ ref.title }}</base-title> -->
      </div>
    </base-grid>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'homeReferences',

  props: {
    textContent: Object  
  },

  created() {
     this.$api.getData("refs", {}, "app.constlet.refs")
  },

  computed: {
    ...mapState('app', ['constlet']),
    ...mapGetters('app', ['GET_BREAKPOINTS']),

    styling() {
      let title = 'l'
      if (this.GET_BREAKPOINTS === 'l') {
        title = 'xl'
      }

      return title
    },

    refs() {
      return this.$store.state.app.constlet.refs
    }
  },

  methods: {
    openReference(reference) {
      this.$store.dispatch('SET_STATE', { data: true, path: 'app.ui.preventBodyScrolling' })
      this.$store.dispatch('SET_STATE', { data: true, path: 'modals.showContentHandler' })
      this.$store.dispatch('SET_STATE', { data: reference, path: 'modals.contentOfCurrentReference' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-references {
  transition: all 1.2s ease;
  opacity: 0;
  transform: scale(0.965);
  &.visible {
    // for scollMagic, see: home.vue
    opacity: 1;
    transform: scale(1);
  }

  .item {
    position: relative;
    //height: 20vmin;
    min-height: 160px;
    padding-bottom: 56.25%; // EI TOIMI TÄLLÄISENÄÄN!
    //padding: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0 2px 3px $app-color--shadow, 0 4px 14px $app-color--shadow;
    color: $app-color--theme;
    @extend %clickable;

    .base-title {
      color: $app-color--theme;
    }
    span {
      font-size: 0.9rem;
      font-weight: 500;
    }

    .base-bg {
      transition: transform 0.3s ease;
      transform: scale(1)
    }
    &:hover .base-bg {transform: scale(1.05); }
  }
}
</style>
