  <template>
  <div class="home-process">
    <base-title :size="styling" mB="xl">{{ textContent.section }}</base-title>

    <base-accordion-list class="list">
      <base-accordion-list-item
        v-for="process in processes"
        :key="process.id"
        :itemId="process.id"
      >
        <template #header>{{ process.title }}</template>
        {{ process.text }}
      </base-accordion-list-item>
    </base-accordion-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'homeProcess',

  props: {
    textContent: Object  
  },

  computed: {
    ...mapGetters('app', ['GET_BREAKPOINTS']),

    processes() {
      let processes = [
        { id: 1, title: '', text: '' },
        { id: 2, title: '', text: '' },
        { id: 3, title: '', text: '' },
        { id: 4, title: '', text: '' },
        { id: 5, title: '', text: '' }
      ]

      processes.forEach(process => {
        process.title = this.textContent.content[process.id - 1].title
        process.text = this.textContent.content[process.id - 1].text
      })

      return processes
    },

    styling() {
      let title = 'l'
      if (this.GET_BREAKPOINTS === 'l') { title = 'xl' }

      return title
    }
  }
}
</script>

<style lang="scss" scoped>
.home-process {
  transition: all 1.2s ease;
  opacity: 0;
  transform: scale(0.965);
  &.visible { // for scollMagic, see: home.vue
    opacity: 1;
    transform: scale(1);
  }

  .list {
    max-width: 600px;
    margin: 0 auto;
  }
}
</style>
