<template>
  <div class="home-hero" :style="styling">
      <!-- <base-bg :full=true :parallax=true :parallaxAmount="0.4"></base-bg> -->
      <hero-color-map></hero-color-map>

      <base-content-wrapper-new :resetPadding="true">
        <base-title tag="h1" :size="baseCompSizing.title">{{ textContent.title }}</base-title>
        <editable-content path="hero.text">
          <base-text :size="baseCompSizing.text" :weight=700 :mT="6" :mB="26"></base-text>
        </editable-content>
        <!-- <base-text :size="baseCompSizing.text" :weight=700 :mT="6" :mB="26">{{ textContent.text }}</base-text> -->
        <!-- <base-button :size="baseCompSizing.title" @click="goToContactSection">{{ textContent.button }}</base-button> -->
      </base-content-wrapper-new>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import heroColorMap from '@/components/heroColorMap'

export default {
  name: 'homeHero',
  components: { heroColorMap, editableContent: () => import('@/components/editableContent') },
  props: {
    textContent: Object  
  },

  computed: {
    ...mapGetters('app', ['GET_BREAKPOINTS']),

    baseCompSizing() {
      let title = 'l'
      let text = 'm'

      if (this.GET_BREAKPOINTS === 'l') {
        title = 'xl'
        text = 'l'
      }

      return {
        title: title,
        text: text
      }
    },

    styling() {
      let ui = this.$store.state.app.ui

      return {
        paddingTop: ui.navTopHeight * 2 + 'px',
        paddingBottom: ui.navTopHeight + 'px'
      }
    }
  },

  methods: {
    goToContactSection() {
      const section = document.getElementById('constlet-contact')
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$hero-color: $app-color--theme;

.home-hero {
  position: relative;

  &::v-deep * { color: $hero-color !important; }

  .base-text { line-height: 1.6em !important; }
}
</style>
