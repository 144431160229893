<template>
  <div class="view-home">

    <!-- hero -->
    <base-content-wrapper :resetPadding="true" :resetMaxWidth="true">
      <home-hero
        id="constlet-home"
        :textContent="textContent.hero"
      ></home-hero>
    </base-content-wrapper>

    <!-- services -->
    <base-content-wrapper-new>
      <home-services
        id="constlet-services"
        :textContent="textContent.services"
        :style="sectionStyling"
      ></home-services>
    </base-content-wrapper-new>

    <!-- process -->
    <base-content-wrapper-new>
      <home-process
        id="constlet-process"
        :textContent="textContent.process"
        :style="sectionStyling"
      ></home-process>
    </base-content-wrapper-new>
      
    <!-- references -->
    <base-content-wrapper-new>
      <home-references
        id="constlet-references"
        :textContent="textContent.references"
        :style="sectionStyling"
      ></home-references>
    </base-content-wrapper-new>
      
    <!-- contact -->
    <base-content-wrapper-new>
      <home-contact
        id="constlet-contact"
        :textContent="textContent.contact"
        :style="sectionStyling"
      ></home-contact>
    </base-content-wrapper-new>

    <!-- footer -->
    <app-footer :textContent="textContent.footer"></app-footer>
  </div>
</template>

<script>
import ScrollMagic from 'scrollmagic'
import { mapGetters } from 'vuex'
import homeHero from '@/components/homeHero'
import homeServices from '@/components/homeServices'
import homeProcess from '@/components/homeProcess'
import homeReferences from '@/components/homeReferences'
import homeContact from '@/components/homeContact'
import appFooter from '@/components/appFooter'

export default {
  name: 'viewHome',

  components: {
    homeHero,
    homeServices,
    homeProcess,
    homeReferences,
    homeContact,
    appFooter,
    // dropzone: () => import('@/components/dropzone')
  },
  
  mounted() {
    const controller = new ScrollMagic.Controller()

    const scene1 = new ScrollMagic.Scene({
      reverse: false, // do not reverse the scene once scrolling up
      triggerElement: '#constlet-services',
      triggerHook: 'onEnter',
      offset: 20, // start this scene after scrolling for 50px
    })
    .setClassToggle('#constlet-services', 'visible')
    .addTo(controller) // assign the scene to the controller

    const scene2 = new ScrollMagic.Scene({
      reverse: false,
      triggerElement: '#constlet-process',
      triggerHook: 'onEnter',
      offset: 200
    })
    .setClassToggle('#constlet-process', 'visible')
    .addTo(controller)

    const scene3 = new ScrollMagic.Scene({
      reverse: false,
      triggerElement: '#constlet-references',
      triggerHook: 'onEnter',
      offset: 200
    })
    .setClassToggle('#constlet-references', 'visible')
    .addTo(controller)

    const scene4 = new ScrollMagic.Scene({
      reverse: false,
      triggerElement: '#constlet-contact',
      triggerHook: 'onEnter',
      offset: 200
    })
    .setClassToggle('#constlet-contact', 'visible')
    .addTo(controller)

    this.$on('hook:beforeDestroy', () => {
      controller = controller.destroy(true) // destroys the controller and resets the scene
    })
  },

  computed: {
    textContent () {
      let localization = this.$store.state.app.constlet.inEnglish ? 'en' : 'fi'
      return this.$store.state.content.text[localization]
    },

    sectionStyling() {
      let breakpoints = this.$store.getters['app/GET_BREAKPOINTS']
      let paddingTop = this.$store.state.app.constlet.sectionPaddingTop
      
      return {
        paddingTop: breakpoints === 's'
          ? paddingTop + 'px'
          : paddingTop * 1.4 + 'px'
      }
    }
  },

  data() {
    return {
    }
  },

  methods: {
    klik() {
      console.log('paska');
      
      this.onn = !this.onn
    }
  }
}
</script>

<style lang="scss" scoped>
.view-home {}
</style>
