// Copy text content of HTML input element to clipboard
export const copyToClipboard = function(id) {
  let toCopyFrom = document.getElementById(id)
  let hidden = false

  if (toCopyFrom.getAttribute('type') === 'hidden') {
    toCopyFrom.setAttribute('type', 'text')
    hidden = true
  }

  toCopyFrom.select()
  document.execCommand('copy')

  if (hidden) { toCopyFrom.setAttribute('type', 'hidden') }
  
  // NOTES:
  // -copying to clipboard with THIS code ONLY works with input and textarea elements (at least for now)
  // -easiest is to add additional input element and stylize it (position: absolute; opacity: 0;)
  // -there is a js-package for easy copying to clipboard (clipboard.js)... consider it!
  // -LOOK INTO THIS MORE IN THE FUTURE!!!
}