<template>
  <div class="hero-color-map">
    <div
      v-for="(row, index) in colors"
      :key="index"
      class="colorRow"
      :style="row.topRowStyle"
    >
      <!-- :style="{ height: index === 0 ? '70px' : false, flex: index === 0 ? '0 1 auto' : false, }" -->
      <div
        v-for="(color, index) in row.colors"
        :key="index"
        class="color"
        :style="color"
      ></div>
    </div>
  </div>
</template>

<script>
import { randomIntegerFromInterval } from '@/utils/math'

export default {
  name: 'heroColorMap',

  data() {
    return {
      rows: 14,
      columns: 22,
      colorHue: 150,
      transitionDelayBaseValue: 0.8,
      currentInterval: 3500
    }
  },

  mounted() {
    let transitionInterval = setInterval(() => {
      this.colorHue = randomIntegerFromInterval(65, 300)
      this.transitionDelayBaseValue = randomIntegerFromInterval(5, 15) / 100
      this.currentInterval = randomIntegerFromInterval(20, 50) * 100
    }, this.currentInterval)

    this.$on('hook:beforeDestroy', () => {
      clearInterval(transitionInterval)
    })
  },

  computed: {
    colors() {
      let self = this
      let colorMap = []

      // loops through the rows
      for (let row = 0; row < this.rows; row++) {
        //let rowOfMap = []
        let rowOfMap = {
          colors: [],
          topRowStyle: { // to mimic navTop's appearance
            height: row === 0 ? this.$store.state.app.ui.navTopHeight + 'px' : false,
            flex: row === 0 ? '0 1 auto' : false 
          }
        }

        // loops through the columns on each row
        for (let col = 0; col < this.columns; col++) {
          let colorSaturation = 100 - ((100 / this.columns) * col) + '%'
          let lightnessScale = 80
          let colorLightness = 100 - ((lightnessScale / this.rows) * (this.rows - row)) + '%'
          let transitionDelay = this.transitionDelayBaseValue * (row + col)
          let color = {
            width: 100 / this.columns + '%',
            background: `hsl(${self.colorHue}, ${colorSaturation}, ${colorLightness})`,
            transition: `background 2s linear ${transitionDelay}s`
          }
          rowOfMap.colors.push(color)
        }
        colorMap.push(rowOfMap)
      }
      return colorMap
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-color-map {
  @extend %absolute-0000;
  display: flex;
  flex-direction: column;

  .colorRow {
    width: 100%;
    display: flex;
    flex: 1;

    .color { flex: 1; }
  }
}
</style>
