<template>
  <div class="home-contact">
    <!-- <base-title :size="styling" mB="xl">{{ textContent.section }}</base-title> -->

  <!--   <form @submit.prevent class="form">
      <input ref="email" id="email" name="email" style="position: absolute; opacity: 0; height: 0px;">
      <base-input v-model="inputTextName" :placeholder="textContent.name" mB="l"></base-input>
      <base-input v-model="inputTextEmail" type="email" :placeholder="textContent.email" mB="l"></base-input>
      <base-dropdown v-model="budgetCategories" @itemSelected="itemSelected" :placeholder="textContent.budget" mB="l"></base-dropdown>
      <base-textarea v-model="inputTextSummary" :placeholder="textContent.summary" :maxLength="summaryMaxLength"></base-textarea>
      <base-flex justify="end">
        <base-text>{{ this.countSummaryLength() }} / {{ this.summaryMaxLength }}</base-text>
      </base-flex>
      <base-button @click="submit" :disabled="!allowSubmit" size="xl">{{ textContent.button }}</base-button>
      <p>{{ this.information }}</p>
    </form> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'homeContact',

  props: {
    textContent: Object  
  },
  beforeCreate() {
    this.$api.createCaptchaToken()
  },
  data() {
    return {
      information: '',
      inputTextName: '',
      inputTextEmail: '',
      inputTextBudget: '',
      inputTextSummary: '',
      summaryMaxLength: 2000,
      budgetCategories: [
        '500 - 1000e',
        '1000 - 2000e',
        '2000 - 5000e',
        '5000 - 10 000e',
        '+10 000e',
      ]
    }
  },

  computed: {
    ...mapGetters('app', ['GET_BREAKPOINTS']),

    styling() {
      let title = 'l'
      if (this.GET_BREAKPOINTS === 'l') { title = 'xl' }
      return title
    },

    allowSubmit() {
      if (this.inputTextName && this.inputTextEmail && this.inputTextBudget && this.inputTextSummary) {
        return true
      } else return false
    }
  },

  methods: {
    itemSelected(item) {
      this.inputTextBudget = item
    },

    countSummaryLength() {
      return this.inputTextSummary.split('').length
    },

    sendMail(data) {
      return new Promise((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        const theUrl = this.$store.state.content.meta.mailSender || 'https://us-central1-constlet.cloudfunctions.net/emailMessage';
        xmlhttp.open("POST", theUrl);
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.send(JSON.stringify(data));
        resolve('Message sent to Constlet. We\'ll respond ASAP!')
      })     
    },

    async submit() {
      if (this.$refs.email && this.$refs.email.value) { return } // check if honeypot is filled

      const data = {
        to: this.$store.state.content.meta.contactEmail,
        from: "Constlet",
        subject: "Uusi yhteydenotto kotisivuilta",
        html: "<div><h3>Yhteydenotto:</h3>"
              +"<p>Nimi: " +this.inputTextName +"</p>"
              +"<p>Maili: " +this.inputTextEmail +"</p>"
              +"<p>Budjetti: " +this.inputTextBudget +"</p>"
              +"<p>Summary: " +this.inputTextSummary +"</p>"
      }

      const dataToFirebase = {
        date: new Date().toISOString(),
        name: this.inputTextName,
        mail: this.inputTextEmail,
        budget: this.inputTextBudget,
        summary: this.inputTextSummary
      }
     
      // alert('TÄHÄN KOOLPÄKKIÄ! UNOHTAMATTA LOADERIANIMAATIOO')
      // start loader animation
      // älä lähetä ellei email validi
      //alert('Message sent to Constlet. We\'ll respond ASAP!')

      this.information = 'Checking whether you are human or not...'
      const passed = await this.$api.reCaptchaValidate()
      if (passed) {
        let result = await this.sendMail(data)
        this.information = result
      } else {
        this.information = 'Could not confirm you as a human :( please, try again or use our mail address.'
        dataToFirebase.validationFail = true
      }
      this.$api.addData('mail', dataToFirebase)
      // nollaa inputTextBudget dropdown
      this.inputTextName = ''
      this.inputTextEmail = ''
      this.inputTextBudget = ''
      this.inputTextSummary = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.home-contact {
  margin-bottom: 4.5rem;
  transition: all 1.2s ease;
  opacity: 0;
  transform: scale(0.965);
  &.visible { // for scollMagic, see: home.vue
    opacity: 1;
    transform: scale(1);
  }

  .form {
    max-width: 600px;
    margin: 0 auto;
  }
}
</style>
