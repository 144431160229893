<template>
  <div class="home-services">
    <base-title :size="styling.title.main" mB="xl">{{ this.textContent.section }}</base-title>

    <div class="grid" :style="styling.grid">
      <div v-for="service in services" :key="service.title">
        <base-flex center="y" mB="m">
          <base-icon mR="m" :mB="1.2" size="l">{{ `constlet-${service.icon}` }}</base-icon>
          <base-title :size="styling.title.secondary">{{ service.title }}</base-title>
        </base-flex>
        <base-text>{{ service.text }}</base-text>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'homeServices',

  props: {
    textContent: Object  
  },

  computed: {
    ...mapGetters('app', ['GET_BREAKPOINTS']),

    services() {
      let services = [
        { title: 'Web', icon: 'web', text: '' },
        { title: 'Design', icon: 'design', text: '' },
        { title: 'Audio', icon: 'audio', text: '' },
        { title: 'Video', icon: 'video', text: '' }
      ]
      
      services.forEach(service => {
        service.text = this.textContent[service.title.toLowerCase()]
      })

      return services
    },

    styling() {
      let windowWidth = this.$store.state.app.window.width
      let titles = { main: 'l', secondary: 's' }
      let columns, rows

      if (this.GET_BREAKPOINTS === 'l') {
        titles.main = 'xl'
        titles.secondary = 'm'
      }

      if (windowWidth < 550) { columns = '1fr' }
      else if (windowWidth < 950) { columns = '1fr 1fr' }
      else { columns = '1fr 1fr 1fr 1fr' }

      return {
        title: {
          main: titles.main,
          secondary: titles.secondary,
        },
        grid: { gridTemplateColumns: columns },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-services {
  transition: all 1.2s ease;
  opacity: 0;
  transform: scale(0.965);
  &.visible { // for scollMagic, see: home.vue
    opacity: 1;
    transform: scale(1);
  }

  .grid {
    display: grid;
    grid-gap: 2rem;
  }
}
</style>
